import React from "react";

import { useState, useEffect } from "react";

import './landing.css';
import Banner from '../../Components/Banner/Banner';
import Navbar from "../../Components/Navbar/Navbar";
import ClubSlider from "../../Components/ClubSlider/ClubSlider";

import {ReactComponent as Play} from '../../assets/landing/play.svg';
import InfinityPlans from "../../Components/InfinityPlans/InfinityPlans";
import ElevateYourTeam from "../../Components/ElevateYourTeam/ElevateYourTeam";

//import {ReactComponent as Background} from '../../assets/landing/textura infinity.svg';
import Footer from "../../Components/Footer/Footer";

import Faqs from '../../Components/Faqs/Faqs';
import Gallery from "../../Components/Gallery/Gallery";

//import Video from '../../assets/landing/seleccion_entrenamientowebm.webm';
import Video from '../../assets/landing/compressed_bg.mp4';
import WhyArgentina from "../../Components/WhyArgentina/WhyArgentina";
import Contact from "../../Components/Contact/Contact";
import InstagramRow from "../../Components/InstagramRow/InstagramRow";

import VideoPoster from '../../assets/landing/video_placeholder.webp';

import { PopupModal } from "react-calendly";

function Landing() {

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, [])

    const [isOpen, setIsOpen] = useState(false);



    return (
        <>

    <PopupModal
        url="https://calendly.com/argentinasoccerexperience/30min?hide_gdpr_banner=1"
        pageSettings={{backgroundColor: '00FFFFFF', overflow: 'hidden', scrollbar: 'none', height: '750px'}}
        //utm={this.props.utm}
        //prefill={this.props.prefill}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById("root")}
    />

    <div className="centering-wrapper" >
        <Banner/>
        <Navbar openPopUp={setIsOpen}/>
        <div className="cover-wrapper">
            
            <div className="cover-background" style={loaded ? {opacity: 1} : {opacity: 0}}>
                {/*<img src={Messi} className="cover-image" alt="cover-image"/>*/}
                <video src={Video} playsInline autoPlay loop muted disablePictureInPicture preload="auto">

                </video>
                

            </div>
            
            <div className="max-width-container-1200">
                {/*<Navbar/>*/}
                <div className="cover-text-container">
                    <Play/>
                    <div className="cover-text-first-row">
                        <div className="cover-text-pill">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="about" className="at-infinity-wrapper">
            <div className="at-infinity-column justify-right" style={{backgroundColor: '#BFFF78'}}>
                <div className="left-column-text">
                    At Infinity Global <br/> Sports, we <br/> specialize in elite <br/> soccer programs 
                    <br/> in Argentina
                </div>
            </div>
            <div className="at-infinity-column justify-left">
                <div className="right-column-text">
                Enhance your soccer skills with our one or two-week programs in Argentina. Train in top facilities, learn from professional coaches, and compete against strong teams.
                </div>
            </div>
        </div>
        <ClubSlider/>
        <ElevateYourTeam/>
        <div className="partnering-wrapper">
            <div className="partnering-text">
                 
            By partnering with the Argentina Football Association (AFA) and top clubs, we have exclusive access to the best facilities, pro coaches, and the best network to find the right teams to play against your team with a similar competitive level.

            </div>
        </div>
        <Gallery/>
        <div className="dark-mode-wrapper">
            <InfinityPlans openPopUp={setIsOpen}/>
            <WhyArgentina/>
            <Faqs/>
            <InstagramRow/>
            <Contact/>
            <Footer openPopUp={setIsOpen}/>
        </div>

</div>


</>
    )
}

export default Landing;