import React from "react";

import Cups from '../../assets/landing/cups.webp';

import './whyargentina.css';

function WhyArgentina() {



    return (
        <>
        <div className="centering-wrapper">
                <div className="whyarg-wrapper">
                    <div className="whyarg-header-container">
                        <div className="white-line"></div>
                        <div className="whyarg-header">
                            WHY <br/> ARGENTINA
                        </div>
                        <div className="white-line"></div>
                    </div>
                    
                    <div className="whyarg-description-container">
                        <div className="whyarg-description">
                            You'll feel just like a member of the national team, training at the same top-tier facilities as the world champions. You'll play on the same fields as the professionals and enjoy exclusive coaching from top Argentine coaches.
                        </div>
                    </div>
                    <div className="whyarg-cups-row">
                        <img className="cups-image" src={Cups} alt="Argentina's trophies"/>
                    </div>
                   <div className="cup-description">
                        WORLD CUP 2022
                   </div>
                </div>
        </div>
        </>
    )
}

export default WhyArgentina;