import React from "react";
import { useEffect } from "react";

import { useToggleFade } from "../../Hooks/useToggleFade";
import './banner.css';

function Banner() {
    
    //TODO: animation for banner

    return (
        <>
        <div className="banner-wrapper">
            <div className="banner-container">
                <div className="banner-content banner-animation">
                    <div className="banner-text">
                        Limited Slots for 2024 – Book Now!
                    </div>
                    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="1.5" fill="#D9D9D9"/>
                    </svg>
                    <div className="banner-text">
                        Pre-register for 2025 Trips!
                    </div>
                </div>
            </div>
            {/*
            <div className="banner-container">
                <div className="banner-content banner-animation reverse-animation" >
                    <div className="banner-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="2" cy="2" r="1.5" fill="#D9D9D9"/>
                    </svg>
                    <div className="banner-text">
                        Pre-register for 2025 Trips!
                    </div>
                </div>
            </div>
            */}
        </div>
        </>
    )
}

export default Banner;