import React, { useEffect, useState } from "react";
import './faqs.css';
import './gastronomia.css';
import FaqRow from "./FaqRow.js";

import MockFaqs from './mock-faq-response.json';



function Faqs ({color, style}) {

    const shopsDataUse = MockFaqs;

    useEffect(() => {
        setFaqData(shopsDataUse);
        setFilteredFaqs(shopsDataUse);
    }, [shopsDataUse])

    //Current implementation is load every faq and hide extras. If it scales, load more on req/res

    const [faq, setFaq] = useState("");
    const [faqData, setFaqData] = useState(MockFaqs);
    const [filteredFaqs, setFilteredFaqs] = useState(MockFaqs);

    const [currentRow, setCurrentRow] = useState(-1);
    /*
    const terms = ["todas", "sobre ronda", "como llegar", "que hay", "comida", "bebidas"];
    const filterParams = ["", "sobre-ronda", "como-llegar", "que-hay", "comida", "bebidas"];
    */
    const terms = ["all", "travel", "training", "residency", "payment / cancellation"];
    const filterParams = ["", "travel", "training", "residency", "payment"];

    const [displayLimit, setDisplayLimit] = useState(7);
    const [loadText, setLoadText] = useState("Load more");

    const [lastIncrement, setLasIncrement] = useState(3);

    const [faqsLength, setFaqsLength] = useState(Object.keys(filteredFaqs).length);

    const closeOtherFaqRows = (i) => {
        setCurrentRow(i);
        
    }

  
    /*
    const handleLoadMore = () => {
        //let faqsLength = Object.keys(filteredFaqs).length;
        if(faqsLength > 7) {
            if (displayLimit >= faqsLength) {
                setDisplayLimit(displayLimit-lastIncrement);
            } else {
                //setDisplayLimit(displayLimit + (faqsLength - displayLimit));
                //setLasIncrement(faqsLength - displayLimit);
                setDisplayLimit(displayLimit + lastIncrement);
                setLasIncrement(faqsLength);
            }
    }
    }
*/

    const determineFaqIncrement = () => {
        let faqsLeft = Object.keys(filteredFaqs).length - displayLimit;
        if (faqsLeft >= 3) {
            return 3;
        } else {
            return faqsLeft;
        }
    }  

    const handleLoadMore = () => {
        const increment = determineFaqIncrement();
        if (displayLimit < Object.keys(filteredFaqs).length) {
            setDisplayLimit(displayLimit + increment);
        } else {
           
            setDisplayLimit(7);
        }
       
    }

    useEffect(() => {
        if (displayLimit >= Object.keys(filteredFaqs).length) {
            setLoadText("Load less")
        } else {
            setLoadText("Load more")
        }
    }, [displayLimit])


    useEffect(() => {
        const filtData = faqData.filter(item => 
            item.type.toLowerCase().includes(faq.toLowerCase()) 
        );
        setFilteredFaqs(filtData);
    }, [faq])

    const [filterDropdown, setFilterDropdown] = useState(false);

    const handleDropdownClick = () => {
        if (window.innerWidth < 478) {
            setFilterDropdown(!filterDropdown);
        }
    }

    const determineDropdownStyle = () => {
        if (window.innerWidth < 478) {
            if (filterDropdown) {
                return {display: 'flex'}
            } else {
                return {display: 'none'}
             }
        }
        return {display: 'flex'}
    }

    return (
        <>
        <div className="centering-wrapper">
        <div className="faqs-wrapper" style={style}>
            <div>
                <div className="faqs-title-bar-wrapper">
                    <div className="faqs-title">
                        FAQS
                    </div>
                    <div className="filter-bar-wrapper" style={{backgroundColor: '#34373F', marginTop: 'min(2.5vw, 36px)'}} onClick={handleDropdownClick}>
                        <div className="selected-type-wrapper hide-desktop">
                            <div className="selected-text">{terms[filterParams.indexOf(faq)]}</div>
                                <svg width="20" height="11" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.11475 0.78479L10.1147 9.78479L19.1147 0.78479" stroke="white"/>
                                </svg>

                        </div>
                        <div className="filter-buttons-container" style={determineDropdownStyle()}>
                            {terms.map((item, index) => {
                            return <div className="faq-button" 
                            key={index} 
                            style={faq === filterParams[index] ? {backgroundColor: '#000000'} : {}}
                            onClick={() => {setFaq(filterParams[index]); handleDropdownClick(); setDisplayLimit(7); setLasIncrement(3); setLoadText("Load more");}}
                            >
                                {item}
                            </div>  
                            })}
                        </div>
                        
                    </div>
                </div>

                <div className="faqs-container">
                    {Object.keys(filteredFaqs).length !== 0 ? 
                    filteredFaqs.slice(0, displayLimit).map((item, index) => {
                        return <FaqRow rowData={item} informClick={closeOtherFaqRows} current={currentRow} index={index}/> 
                    }): <div>Sin Resultados!</div>}
                </div>
            </div>
            <div className="load-more-faqs-container" onClick={() => {handleLoadMore()}} style={Object.keys(filteredFaqs).length >= 7 ? {opacity: 1} : {opacity: 0}}>
                <div className="load-more-text">
                    {loadText}
                </div>
                <div className="load-more-icon">
                    <div className="hide">
                    <svg width="100%" height="100%" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.30957 1.55176L9.30957 17.3613" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    <path d="M17.3096 9.55176L1.5 9.55176" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    </div>

                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default Faqs;