import React from "react";
import './infinityplans.css';
import { useState, useEffect, useRef } from "react";

import {ReactComponent as PlanLogo} from '../../assets/landing/plan-logo.svg';

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import Slide from '../../assets/landing/slide.webp';
import SlideImage2 from '../../assets/landing/messi-cover.webp';

import { useSlider } from "../../Hooks/useSlider";


function PlanPopUp({show, closeCallback, data, openCalendlyPopUp}) {

    const {splideRef, handleMovement} = useSlider();

    const [isOpen, setIsOpen] = useState(false);

    const divRef = useRef(null);
    const handleShow = (open) => {
        if (divRef.current != null) {
            if(open) {
                divRef.current.style.display = 'flex';          
                setTimeout(() => {
                    divRef.current.style.opacity = 1;
                }, 20);
            } else {
                divRef.current.style.opacity = 0;
                setTimeout(() => {
                    divRef.current.style.display = 'none';
                    closeCallback();
                }, 300);
            }
        }
    }

    useEffect(() => {
        handleShow(show);
       }, [])


       const DescriptionItem = ({data}) => {
        return (
            <div className="description-row">
                <div className="popup-description-title">
                    {data.activity}
                </div>
                <div className="row-list">
                    <ul>
                        {data.details.map((detail, index) => {
                            return (
                                <li key={index}>{detail}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
       }


    return (
        <>
        <div ref={divRef} className="background-overlay">
            <div className="popup-wrapper">
                    <div className="close-icon-wrapper">
                        <div className="close-icon" onClick={() => handleShow(false)}>
                            <svg width="58" height="58" viewBox="0 0 58 58" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M43.8579 14.8491L14.8665 43.8405" stroke="currentColor" stroke-linecap="round"/>
                            <path d="M43.1509 43.8406L14.1595 14.8492" stroke="currentColor" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                <div className="popup-container">

                    <div className="popup-title-container">

                        <div className="popup-title">
                            {data.title}
                        </div>

                        <div className="know-more-button special-hover" onClick={() => {openCalendlyPopUp(true)}} style={{width: 'fit-content'}}>
                            Let's Chat
                        </div>
                    </div>

                    <div className="popup-description-container">

                        {
                            data.description.map((item, index) => {
                                return (
                                    <DescriptionItem data={item} key={index}/>
                                )
                            })
                        }
                        <div className="popup-description-title">*Each activity is subject to availability</div>
                        
                    </div>
                    <div className="popup-splide-container">
                        <div className="popup-arrows-container">
                        <div className="button-arrow popup-arrow" style={{transform: 'rotate(180deg)'}} onClick={ () => {handleMovement('prev')}}>
                                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 4H11" stroke="currentColor"/>
                                <path d="M8.5 1L11.5 4L8.5 7" stroke="currentColor"/>
                                </svg>
                            </div>
                            <div className="button-arrow popup-arrow" onClick={() => {handleMovement('')}}>
                                <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 4H11" stroke="currentColor"/>
                                <path d="M8.5 1L11.5 4L8.5 7" stroke="currentColor"/>
                                </svg>
                            </div>

                        </div>
                        <Splide ref={splideRef}
                            options={{
                                type: "fade",
                                rewind: true,
                                perPage: 1,
                                autoplay: true,
                                autoWidth: true,
                                pagination: false,
                                arrows: false,
                                interval: 2000,
                                speed: 1000,
                            }}
                        >
                            {
                              data.popupImages.map((image, index) => {
                                return (
                                <SplideSlide>
                                    <img src={image} alt="" className="slide-image" key={index}/>
                                </SplideSlide>
                                )
                              })
                            }

                        </Splide>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default PlanPopUp;