import { useState, useRef } from "react";

export function useToggleFade() {
    const [fade, setFade] = useState(false);
    const element = useRef(null);

    const handleFade = (duration) => {
        if (element.current) {
            
            if (!fade) {
                element.current.style.opacity = 0;
                setTimeout(() => {
                    element.current.style.display = 'none';
                }, duration);
            } else {
                element.current.style.display = 'flex';
                setTimeout(() => {
                    element.current.style.opacity = 1;
                }, 1);
            }
            setFade(!fade);
        }
    }

    const handleFadeWithoutDisplay = () => {
        if (element.current) {
            if (!fade) {
                element.current.style.opacity = 0;
            } else {
                element.current.style.opacity = 1;
            }
            setFade(!fade);
        }
    }

    



    return ({fade, setFade, element, handleFade, handleFadeWithoutDisplay});


}