import React, { useEffect, useState } from "react";
import axios from "axios";
import "./instagramrow.css";

import { InfinitySpin } from "react-loader-spinner";

function InstagramRow() {
    const [posts, setPosts] = useState(null);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://infinity-form-handling.vercel.app/instagram-posts',
            headers: {}
        };

        axios.request(config)
            .then((response) => {
               
                setPosts(response.data.data.data); // Set posts to the data property within the response
            })
            .catch((error) => {
                
            });
    }, []);

    const handleImageError = (event) => {
        event.target.style.display = 'none';
    };

    const isMac = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMac = userAgent.includes('macintosh') || userAgent.includes('mac os x');
        return isMac;
    };

    const isOsMac = isMac();
    
    return (
        <>
            <div className="centering-wrapper">
                <div className="instagram-row-wrapper">
                    <div className="instagram-header-container">
                        <div className="white-line hide-desktop"></div>
                        <div className="instagram-tag">
                            @infinityglobalsports
                        </div>
                        <div className="white-line"></div>
                    </div>
                    <div className="instagram-posts-row">
                    {
                posts ?
                    posts.map((post, index) => {
                        const mediaUrl = post.media_url;
                        const isVideo = mediaUrl.includes('_video_dashinit.mp4') || mediaUrl.includes('_video_dashinit.webm') || mediaUrl.includes('_video_dashinit.avi'); // Add more video extensions as needed

                        return (
                            !isOsMac && isVideo ? (
                                <video
                                    className="insta-post"
                                    src={mediaUrl}
                                    key={index}
                                    alt="Instagram Post Video"
                                    onError={handleImageError}
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    disablePictureInPicture
                                    preload="auto"
                                    
                                >
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img
                                    className="insta-post"
                                    src={mediaUrl}
                                    key={index}
                                    alt="Instagram Post"
                                    onError={handleImageError}
                                />
                            )
                        );
                    })
                    : <InfinitySpin visible={true} width="200" color="#BFFF78" ariaLabel="Loading Instagram Posts" />
            }
                    </div>
                </div>
            </div>
        </>
    );
}

export default InstagramRow;
