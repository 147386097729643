import React from "react";
import './navbar.css';

import { useState, useEffect, useRef } from "react";

import {ReactComponent as Logo} from '../../assets/landing/logo.svg';

import { HashLink } from 'react-router-hash-link';

import useWindowWidth from "../../Hooks/useWindowWidth";

function Navbar({openPopUp}) {

    const windowWidth = useWindowWidth();

    const [isInView, setIsInView] = useState(true);
    //scroll up functionality
    const [scrolledUp, setScrolledUp] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const elementRef = useRef(null);
  
    const [isAtTop, setIsAtTop] = useState(true);

    useEffect(() => {
      const handleScroll = () => {
        
        const currentScrollPos = window.scrollY;
        const element = elementRef.current;
        
        if (element) {
          //const elementOutOfView = element.getBoundingClientRect().bottom < 0;
          
          if (currentScrollPos < prevScrollPos) {
                setScrolledUp(true);
          } else {
                setScrolledUp(false);
          }
          
          setPrevScrollPos(currentScrollPos);
        }

        if(window.scrollY === 0) {
            setIsAtTop(true);
        } else {
            setIsAtTop(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [prevScrollPos]);


    const navref = useRef(null);

    //basically, first scroll down doesnt toggle opacity 0 for the element, causing it to appear suddenly afterwards
    const handleScrollToggleasd = () => {
        if (navref.current) {
            
            if(scrolledUp && elementRef.current.getBoundingClientRect().bottom < 0) {
                if(!isInView) {
                    
                    //navref.current.style.position = 'fixed';
                    //navref.current.style.top = 0;
                    
                    setTimeout(() => {
                        //style={!isAtTop && windowWidth < 478 ? {paddingTop: '16px'} : {paddingTop: 0}}
                        navref.current.style.opacity = 1;
                        
                    }, 50);
                    setIsInView(true);
            }
            } else if (!scrolledUp){
                    if(isInView) {
                        if (elementRef.current.getBoundingClientRect().bottom < 0) {
                            navref.current.style.opacity = 0;
                        } else {
                            navref.current.style.opacity = 1;   
                        }
                        setTimeout(() => {
                            //navref.current.style.position = 'relative';
                        }, 301);
                        setIsInView(false);
                }
            } 
                
        }
    }

    const handleScrollToggle = () => {
    
        if (navref.current && windowWidth > 478) {
            if (!scrolledUp) {
                navref.current.style.opacity = 0;
                setIsInView(false);
                setTimeout(() => {
                    navref.current.style.visibility = 'hidden';
                    if (windowWidth < 478 && isAtTop) {
                        navref.current.style.paddingTop = '0px';
                    }
                    
                }, 300);
            } else {
                navref.current.style.visibility = 'visible';
                navref.current.style.opacity = 1;
                if (windowWidth < 478 && !isAtTop) {
                    navref.current.style.paddingTop = '16px';
                }
                
                setIsInView(true);
            }
        }
    
    }
    

    useEffect(() => {
        handleScrollToggle();
    }, [scrolledUp])



    //scroll up functionality--

    return (
        <>
        <div ref={elementRef} className="navbar-position-ref"></div>
        <div className="navbar-wrapper"  style={!isAtTop ? {marginTop: 0} : {}}>
            <div className="absolute-background" style={!isAtTop && isInView ? {opacity: 1} :  {opacity: 0}}></div>
            <div ref={navref} className="navbar-container" style={isAtTop ? {paddingTop: 0} : {}}>
                <div className="navbar-logo-container">
                    <Logo/>
                </div>
                <div className="navbar-links-container">
                    <div className="navbar-buttons-container">
                        <HashLink smooth to={'/#about'} style={{textDecoration: 'none'}}>
                        <div className="navbar-button">
                            ABOUT
                        </div>
                        </HashLink>
                        <HashLink smooth to={'/#plans'} style={{textDecoration: 'none'}}>
                        <div className="navbar-button">
                            PLANS
                        </div>
                        </HashLink>
                        <HashLink smooth to={'/#contact'} style={{textDecoration: 'none'}}>
                        <div className="navbar-button">
                            CONTACT
                        </div>
                        </HashLink>
                    </div>
                    <div className="navbar-book-button" onClick={() => {openPopUp(true)}}>
                        Let's Chat
                    </div>
                </div>
            </div>
        
        </div>
        
        </>
    )
}

export default Navbar;