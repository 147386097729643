import React from "react";
import './infinityplans.css';

import { useState } from "react";

import {ReactComponent as PlanLogo} from '../../assets/landing/plan-logo.svg';

import Cover from '../../assets/landing/90.webp';

import { useToggleFade } from "../../Hooks/useToggleFade";
import PlanPopUp from "./PlanPopUp";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/react-splide/css';

import useWindowWidth from "../../Hooks/useWindowWidth";

import MobileBg from '../../assets/landing/mobile-bg.webp';



function Plan({data, openPopUp, openCallBack}) {

    const [hover, setHover] = useState(false);

    const { element, handleFade } = useToggleFade();

    const [showPopup, setShowPopup] = useState(false);

    const closeFromPopUp = () => {
        setShowPopup(false);
    }

    const windowWidth = useWindowWidth();

    const determineStyleAction = () => {
        if (windowWidth > 478) {
            return hover ? {backgroundColor: 'rgba(237, 237, 237, 0.4)'} : {backgroundColor: 'rgba(0, 0, 0, 0.3)'}
        }
        return {};
    }

    return (
        <>
        {
            //showPopup && windowWidth > 478 ? <PlanPopUp openCalendlyPopUp={openPopUp} data={data} show={showPopup} closeCallback={closeFromPopUp}/> : null
        }
        <div className="plan-wrapper" 
        onMouseOver={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}} style={determineStyleAction()}
        onClick={() => {handleFade(500)}}
        >
            <img className="mobile-plan-background-container hide-desktop" src={MobileBg}/>
            <div className="plan-container" onClick={() => {setShowPopup(!showPopup)}}>
                <div className="plan-column-1">
                    <div className="plan-logo-row">
                        <div className="plan-logo-svg-wrapper">
                            <PlanLogo/>
                        </div>
                        <div className="plan-pill">
                            {data.pillTag}
                        </div>
                    </div>
                    <div className="plan-title">
                        {data.title}
                    </div>
                    <div className="mobile-bottom-price-column hide-desktop">
                        <div className="plan-price-container">
                            <div className="plan-currency hide">
                                $
                            </div>
                            <div className="plan-price">
                                {`$ ${data.price}`}
                            </div>
                        </div>
                        <div className="mobile-whats-included-container">
                            <div className="mobile-whats-included-text">
                                What's included
                            </div>
                            <div className="mobile-plan-button">
                                <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0.664307L4 3.66431L1 6.66431" stroke="#BFFF78"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plan-column-2 hide">
                    <div className="plan-image-container">
                        <Splide
                        options={{
                            type: 'fade',
                            rewind: true,
                            autoplay: true,
                            autoWidth: true,
                            width: '100%',
                            arrows: false,
                            pagination: false,
                            perPage: 1,
                            interval: data.interval,
                            breakpoints: {
                                478: {
                                    destroy: true,
                                }
                            }
                        }}
                        >
                            {data.images.map((image, index) => {
                                return (
                                    <SplideSlide key={index}>
                                        <div className={`plan-cover-image-container `}>
                                            <img className={`plan-cover-image${data.positionClass[index]}id${data.id}`} src={image} alt="plan-cover-image"/>
                                        </div>
                                    </SplideSlide>
                                )
                            })}
                        </Splide>
                    </div>
                </div>
                <div className="plan-column-3 hide">
                    <div className="plan-description-text">
                        What's included:
                    </div>
                    <div className="plan-description-text">
                        <ul className="plan-list">
                            {
                                data.whatsIncluded.map((item, index) => {
                                    return <li key={index}>{item}</li>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="hide">
                <svg width="1" height="311" viewBox="0 0 1 311" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.5" x2="0.5" y2="311" stroke="#596378"/>
                </svg>
                </div>
                <div className="plan-column-4 hide">
                    <div className="plan-price-container" style={hover ? {color: '#BFFF78'} : {color: '#B1B1B1'}}>
                        <div className="plan-currency">
                            $
                        </div>
                        <div className="plan-price">
                            {data.price}
                        </div>
                    </div>
                    <div className="plan-button-row">
                        <div className="button-arrow" style={hover ? {backgroundColor: '#BFFF78', color: '#000000', borderColor: 'transparent'} : {color: '#FFFFFF'}}>
                        <svg width="13" height="8" viewBox="0 0 13 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 4H11" stroke="currentColor"/>
                            <path d="M8.5 1L11.5 4L8.5 7" stroke="currentColor"/>
                        </svg>

                        </div>
                        <div className="know-more-button" style={hover ? {backgroundColor: '#BFFF78', color: '#000000', borderColor: 'transparent'} : {color: '#FFFFFF'}}>
                            Know more
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Plan;