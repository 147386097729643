import React from "react";
import { useState } from "react";

import './footer.css';

import {ReactComponent as Logo} from '../../assets/landing/footer-logo.svg';
import {ReactComponent as Caption} from '../../assets/landing/footer/footer-tag.svg';

import {HashLink} from 'react-router-hash-link';

import useWindowWidth from "../../Hooks/useWindowWidth";

function Footer({openPopUp}) {

    const windowWidth = useWindowWidth();

    const Line = () => {
        return (
            <svg class="hide" width="1" height="100%" viewBox="0 0 1 283" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.62474" y1="282.407" x2="0.624752" y2="0.407227" stroke="#5A6C7A" stroke-width="0.750521"/>
            </svg>
        )
    }

    const HorizontalLine = () => {
        return (
            <svg class="hide" width="100%" height="2" viewBox="0 0 1348 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.25" y1="1.03197" x2="1348" y2="1.03208" stroke="#5A6C7A" stroke-width="0.750521"/>
            </svg>
        )
    }

    const SmallVerticalLine = () => {
        return (
            <svg class="hide" width="1" height="100%" viewBox="0 0 1 125" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="0.62474" y1="124.157" x2="0.62474" y2="0.407219" stroke="#5A6C7A" stroke-width="0.750521"/>
            </svg>

        )
    }

    const LinkedinLogo = () => {
        return (
            <svg width="100%" height="100%" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.519 3.33158C5.519 4.77218 4.35116 5.94002 2.91056 5.94002C1.46996 5.94002 0.302124 4.77218 0.302124 3.33158C0.302124 1.89098 1.46996 0.723145 2.91056 0.723145C4.35116 0.723145 5.519 1.89098 5.519 3.33158ZM0.302205 8.17561H5.63534V24.5715H0.302205V8.17561ZM20.1619 8.36789C20.143 8.36193 20.1244 8.3558 20.1059 8.34967C20.0688 8.33742 20.0317 8.32516 19.9919 8.31423C19.9204 8.29784 19.8488 8.28442 19.7758 8.2725C19.4926 8.21586 19.1826 8.17561 18.8189 8.17561C15.7096 8.17561 13.7377 10.4368 13.0878 11.3102V8.17561H7.75464V24.5715H13.0878V15.6283C13.0878 15.6283 17.1182 10.0149 18.8189 14.1378V24.5715H24.1505V13.5073C24.1505 11.03 22.4528 8.9656 20.1619 8.36789Z" fill="white"/>
            </svg>
        )
    }

    const InstaLogo = () => {
        return (
            <svg width="100%" height="100%" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.7548 0.842285H16.698C20.8134 0.842285 24.1507 4.17959 24.1507 8.29496V17.2382C24.1507 21.3535 20.8134 24.6909 16.698 24.6909H7.7548C3.63943 24.6909 0.302124 21.3535 0.302124 17.2382V8.29496C0.302124 4.17959 3.63943 0.842285 7.7548 0.842285ZM16.6992 22.4553C19.5759 22.4553 21.9161 20.1152 21.9161 17.2384V8.2952C21.9161 5.41847 19.5759 3.07833 16.6992 3.07833H7.75598C4.87925 3.07833 2.53911 5.41847 2.53911 8.2952V17.2384C2.53911 20.1152 4.87925 22.4553 7.75598 22.4553H16.6992Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.26526 12.7668C6.26526 9.47424 8.93481 6.80469 12.2274 6.80469C15.52 6.80469 18.1895 9.47424 18.1895 12.7668C18.1895 16.0594 15.52 18.729 12.2274 18.729C8.93481 18.729 6.26526 16.0594 6.26526 12.7668ZM8.50077 12.7671C8.50077 14.821 10.1731 16.4934 12.2271 16.4934C14.2811 16.4934 15.9534 14.821 15.9534 12.7671C15.9534 10.7116 14.2811 9.04072 12.2271 9.04072C10.1731 9.04072 8.50077 10.7116 8.50077 12.7671Z" fill="white"/>
            <path d="M18.6362 7.15251C19.0753 7.15251 19.4312 6.79657 19.4312 6.3575C19.4312 5.91843 19.0753 5.5625 18.6362 5.5625C18.1971 5.5625 17.8412 5.91843 17.8412 6.3575C17.8412 6.79657 18.1971 7.15251 18.6362 7.15251Z" fill="white"/>
            </svg>
        )
    }

    const FooterBookButton = ({children}) => {
        return (
            <div className="footer-book-button"  onClick={() => {openPopUp(true)}}>
                {children}
            </div>
        )
    }

    const FooterLink = ({link, children, style}) => {
        const [hover, setHover] = useState(false);

        return (
            <HashLink smooth to={link} style={{all: 'initial', width: '100%'}}>
                <div className="footer-link-wrapper" onMouseOver={() => {setHover(true)}} on onMouseLeave={() => {setHover(false)}}>
                    <div className="footer-link-container" style={style}>
                        <div className="footer-link-title" style={hover ? {color: '#BFFF78'} : {color: '#FFFFFF'}}>{children}</div>
                        <div className="footer-link-arrow" style={hover ? {marginLeft: '5%', backgroundColor: '#BFFF78', color: '#131417', borderColor: '#000'} : {}}>
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="0.498124" y1="7.68626" x2="14.0411" y2="7.68626" stroke="currentColor" stroke-width="0.750521" stroke-linecap="round"/>
                            <path d="M7.55627 0.775635L14.1229 7.34225C14.285 7.50434 14.285 7.76714 14.1229 7.92924L7.55627 14.4959" stroke="currentColor" stroke-width="0.750521" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </HashLink>
        )
    }

    const BackToTop = () => {
        return (
            <div className="back-to-top-button-container">
            <HashLink smooth to="#top" style={{all: 'initial', width: 'fit-content', alignSelf: 'flex-end'}}>
            <div className="back-to-top-button">
            <div className="back-to-top-title">
                Back to top
            </div>
            <div className="footer-link-arrow" >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.498124" y1="7.68626" x2="14.0411" y2="7.68626" stroke="currentColor" stroke-width="0.750521" stroke-linecap="round"/>
                <path d="M7.55627 0.775635L14.1229 7.34225C14.285 7.50434 14.285 7.76714 14.1229 7.92924L7.55627 14.4959" stroke="currentColor" stroke-width="0.750521" stroke-linecap="round"/>
                </svg>
            </div>
        </div>
        </HashLink>
        </div>
        )
    }

    const MobileLogo = () => {
        return (
            <svg width="100%" height="100%" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 0.916504V66.8747H66.4582V0.916504H0.5ZM7.1 60.2747V49.7899C12.781 49.7899 17.3342 45.195 17.3342 39.6393C17.3342 34.0836 12.781 29.4887 7.1 29.4887V19.0038C18.5038 19.0038 27.819 28.1937 27.819 39.5975C27.819 51.0013 18.5038 60.1912 7.1 60.1912V60.2747ZM41.562 31.0342L37.7608 28.2355L33.9177 31.0342L35.3797 26.5228L31.5367 23.7659H36.2987L37.7608 19.2545L39.2228 23.7659H43.943L40.1418 26.5228L41.6038 31.0342H41.562Z" fill="white"/>
            </svg>
        )
    }

    const MobileContactButton = ({children}) => {
        return (
            <div className="hide-desktop">
            <HashLink smooth to="/#contact" style={{textDecoration: 'none'}}>
                <div className="footer-book-button mobile-contact-button">{children}</div>
            </HashLink>
            </div>
        )
    }

    return (
        <>
        <div className="centering-wrapper">
        <div className="footer-wrapper">
            <BackToTop/>
            <div className="footer-container">
                <div className="footer-first-row">
                    <div className="footer-side-column">
                        <div className="footer-logo-wrapper">
                            {windowWidth > 478 ? <Logo/> : <MobileLogo/>}
                        </div>
                        {/*mobile-content*/}
                        <div className="mobile-footer-caption hide-desktop">FEEL SOCCER LIKE FOOTBALL</div>
                        {/*mobile-content*/}
                    </div>
                    <Line/>
                    <div className="footer-center-column hide">
                        <div className="footer-caption-wrapper">
                            <div className="socials-column">
                                <a href="https://www.linkedin.com/company/infinityglobalsports/" target="_blank">
                                <div className="socials-logo">
                                    <LinkedinLogo/>
                                </div>
                                </a>
                                <a href="https://www.instagram.com/infinityglobalsports/" target="_blank">
                                    <div className="socials-logo">
                                        <InstaLogo/>
                                    </div>
                                </a>
                            </div>
                            <div className="footer-caption-svg">
                                <Caption/>
                            </div>
                        </div>
                    </div>
                    <Line/>
                    <div className="footer-side-column side-column-right-justify mobile-margin">
                        <FooterBookButton>Let's Chat</FooterBookButton>
                        <MobileContactButton>Contact us</MobileContactButton>
                        <div className="mobile-bottom-links-container">
                            <div className="mobile-socials-row hide-desktop">
                                <a href="https://www.linkedin.com/company/infinityglobalsports/" target="_blank">
                                <div className="socials-logo" style={{marginTop: '-5%'}}>
                                    <LinkedinLogo/>
                                </div>
                                </a>
                                <a href="https://www.instagram.com/infinityglobalsports/" target="_blank">
                                    <div className="socials-logo">
                                        <InstaLogo/>
                                    </div>
                                </a>
                            </div>
                        
                            <div className="mobile-email-info hide-desktop">
                                info@infinityglobalsports.com
                            </div>

                            <div className="mobile-bottom-copyright hide-desktop">
                                {`Copyright ${new Date().getFullYear()} Infinity Global Sports® | All rights reserved`}
                            </div>

                        </div>
                    </div>
                </div>
                <HorizontalLine/>
                <div className="footer-row hide">
                    <div className="footer-side-column">
                        <FooterLink link={'/#about'}>about us</FooterLink>
                    </div>
                    <SmallVerticalLine/>
                    <div className="footer-center-column">
                        <div className="center-column-link-row">
                            <FooterLink link={'/#plans'} style={{justifyContent: 'center', alignItems: 'center'}}>plans</FooterLink>
                        </div>
                    </div>
                    <SmallVerticalLine/>
                    <div className="footer-side-column">
                        <div className="right-padding">
                            <FooterLink link={'/#contact'}>contact</FooterLink>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-row">

                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default Footer;