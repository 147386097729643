import React from 'react';
import { useState, useEffect, useRef } from 'react';
import './contact.css';
import '../../Components/InfinityPlans/infinityplans.css';

import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

import {ThreeDots} from 'react-loader-spinner';

function Contact() {
    
    const siteKey = useRef(null);
    const checkForSiteKey = () => {
        if (process.env.REACT_APP_SITE_KEY != null) {
            siteKey.current = process.env.REACT_APP_SITE_KEY;
        } 
        return siteKey.current;
    }

    const recaptcha = useRef();

    const [formSent, setFormSent] = useState(false); 
    const [formSentError, setFormSentError] = useState(false);
    const [isBeingSent, setIsBeingSent] = useState(false);
    
    async function submitForm(event) {
        setIsBeingSent(true);
        event.preventDefault()
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
          alert('Por favor verificar el reCAPTCHA!')
        } else {
          const res = await fetch('https://infinity-form-handling.vercel.app/verify', {
            method: 'POST',
            body: JSON.stringify({ captchaValue }),
            headers: {
              'content-type': 'application/json',
            },
          })
          const data = await res.json()
          if (data.success) {
            postForm();
          } else {
            alert('reCAPTCHA validation failed!')
            setIsBeingSent(false);
          }
        }
      }


      const postForm = () => {
        setIsBeingSent(false);
        let data = JSON.stringify(formData);

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://infinity-form-handling.vercel.app/submit',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
        
        if (response.status === 200) {
            setFormSent(true);
        } else {
            setFormSentError(true);
        }

        })
        .catch((error) => {
        
        setFormSentError(true);
        });

      }


      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phone, setPhone] = useState('');
      const [organization, setOrganization] = useState('');

      const formData = {
        name: name,
        email: email,
        phone: phone,
        organization: organization
      }


    const SuccessMessage = () => {
        return (
            <div className='success-message-container' style={formSent ? {opacity: 1, visibility: 'visible'} : {opacity: 0, visibility: 'hidden'}}>
                    <img className='success-icon' src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/3b/Eo_circle_green_checkmark.svg/512px-Eo_circle_green_checkmark.svg.png?20200417132424'/>
                <div className='success-message-header'>
                    Thank you for reaching out! <br/> We'll be in touch soon.
                </div>
            </div>
        )
    }


    useEffect(() => {
        if (formSent) {
            setTimeout(() => {
                setFormSent(false);
            }, [10000])
        }
    }, [formSent])

    return (
        <>
        <div id="contact" className='centering-wrapper'>
            <div className='contact-form-wrapper'>
                <SuccessMessage/>
                <div className='contact-form-header'>
                    <div className='contact-header'>
                        GET IN TOUCH
                    </div>
                </div>
                <div className='contact-form-container'>
                    <form className='form-element' onSubmit={submitForm}  style={formSent ? {opacity: 0.2, pointerEvents: 'none'} : {}}>
                        <div className='contact-form-input-container'>
                            <label for="name" className='form-label'>* Full name</label>
                            <input type="text" maxlength="50" name="name" className='form-input' required  onChange={(e) => {setName(e.target.value)}}/>
                        </div>
                        <div className='contact-form-input-container'>
                            <label for="email" className='form-label'>* Email address</label>
                            <input type="email" maxlength="50" name="name" className='form-input' required onChange={(e) => {setEmail(e.target.value)}}/>
                        </div>
                        <div className='contact-form-input-container'>
                            <label for="phone number" className='form-label'>* Phone number</label>
                            <input type="tel" min="0"  maxlength="30" name="phone-number" className='form-input' required onChange={(e) => {setPhone(e.target.value)}}/>
                        </div>
                        <div className='contact-form-input-container'>
                            <label for="your organization" className='form-label'>* Club / Organization name</label>
                            <input type="text" maxlength="50" name="organization" className='form-input' required onChange={(e) => {setOrganization(e.target.value)}}/>
                        </div>
                        <div className='form-instruction'>* The marked fields are required</div>
                        <div className='submit-row'>
                            <ReCAPTCHA ref={recaptcha} sitekey={checkForSiteKey()} theme={'dark'}/>
                            <button type="submit" className='send-button'>{!isBeingSent ? 'Send' : <ThreeDots   visible={isBeingSent}
                            height="40"
                            width="40"
                            color="#4fa94d"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""></ThreeDots>}</button>
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
}

export default Contact;