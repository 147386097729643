import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Landing from './Pages/Landing/Landing';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route exact path={'/'} element={<Landing/>}/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
