import React from "react";
import './elevate.css';
import { useState } from "react";
import {ReactComponent as Arrow} from './arrow.svg';
import {ReactComponent as Cover} from '../../assets/landing/active-cover.svg';
import {ReactComponent as CoverIdle} from '../../assets/landing/idle-cover.svg';

import Trophy from '../../assets/landing/icons/trophy.webp';
import Player from '../../assets/landing/icons/player.webp';
import Stadium from '../../assets/landing/icons/stadium.webp';

import {HashLink} from 'react-router-hash-link';

import useWindowWidth from "../../Hooks/useWindowWidth";

import {Splide, SplideSlide} from '@splidejs/react-splide';

function ElevateYourTeam() {

    const windowWidth = useWindowWidth();

    const isMobile = windowWidth < 478;

    const Card = ({title, description, icon}) => {
        const [hover, setHover] = useState(false);

        const cardStyle = !isMobile ? hover ? {maxHeight: '1000px'} : {maxHeight: 'min(35.5vw, 426px)'} : {};

        const subStyle = !isMobile ? hover ? {maxHeight: '1000px', opacity: 1} : {maxHeight: '1px', opacity: 0} : {};

        return (
            <>
            <div className="elevate-card" onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}
                style={cardStyle}>
                        <div className="elevate-card-frame">
                            <div className="elevate-card-idle-cover hide" style={hover ? {opacity: 0} : {opacity: 1}}>
                                <CoverIdle/>
                                
                            </div>
                            <div className="elevate-card-hidden-cover">
                                <Cover/>
                            </div>
                            <div className="elevate-card-icon">
                                <img src={icon ? icon : Trophy} alt="icon"/>
                            </div>
                        </div>
                        <div className="elevate-card-title-container">
                            <div className="elevate-card-title">
                                {title}
                            </div>
                            <div className="elevate-card-subtitle" style={subStyle}>
                                {description}
                            </div>
                        </div>
                    </div>
            </>
        )
    }



    return (
        <>
        <div className="elevate-wrapper">
            <div className="elevate-header-container">
                <div className="elevate-header-title">
                    BE THE COACH YOUR <br/> PLAYERS WILL NEVER <br/> FORGET
                </div>
                <div className="arrow-absolute-container">
                    <Arrow/>
                </div>
                <div className="thing-absolute-container">
                    <svg width="100%" height="100%" viewBox="0 0 66 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.6317 55.5457C13.6317 55.9225 13.4098 56.2308 13.1387 56.2308H0.468359C0.197204 56.2308 0 55.9225 0 55.5457V0.685108C0 0.308301 0.197204 0 0.468359 0H13.1387C13.4098 0 13.6317 0.308301 13.6317 0.685108V6.31181C13.6317 6.68862 13.4098 6.99691 13.1387 6.99691H6.90213V49.2339H13.1387C13.4098 49.2339 13.6317 49.5422 13.6317 49.8847V55.5457Z" fill="black"/>
                    <path d="M34.572 26.5788H39.8038L35.5781 29.6522L37.1879 34.6213L32.9622 31.5479L28.7365 34.6213L30.3463 29.6522L26.0919 26.5788H31.3524L32.9622 21.6096L34.572 26.5788Z" fill="black"/>
                    <path d="M52.2641 55.5457V49.8847C52.2641 49.5422 52.4613 49.2339 52.7324 49.2339H58.969V6.99691H52.7324C52.4613 6.99691 52.2641 6.68862 52.2641 6.31181V0.685108C52.2641 0.308301 52.4613 0 52.7324 0H65.4028C65.6739 0 65.8958 0.308301 65.8958 0.685108V55.5457C65.8958 55.9225 65.6739 56.2308 65.4028 56.2308H52.7324C52.4613 56.2308 52.2641 55.9225 52.2641 55.5457Z" fill="black"/>
                    </svg>
                </div>
            </div>

            <div className="elevate-cards-wrapper hide">
                <div className="elevate-cards-container">
                    <Card icon={Trophy} title={"COMPETITIVE MATCHES"} description={"Participate in competitive matches in Argentina, whether you're a professional or an amateur."}/>
                    <Card icon={Stadium} title={"LIVE PRO MATCHES"} description={"Experience the electrifying atmosphere of professional matches from the top clubs, and enjoy stadium tours."}/>
                    <Card icon={Player} title={"Pro-Coach Sessions"} description={"Train with top-tier coaches at high-caliber facilities, where the World Champion’s team trains."}/>
                </div>
                <HashLink smooth to="/#plans" style={{textDecoration: 'none'}}>
                    <div className="know-more-green">
                        KNOW MORE
                    </div>
                </HashLink>
            </div>

            <div className="card-slider-wrapper hide-desktop">
                <Splide
                    options={{
                        type: "slider",
                        rewind: true,
                        perPage: 1,
                        width: '100%',
                        autoWidth: true,
                        gap: "5px",
                        focus: "center",
                        pagination: false,
                        arrows: false,
                        start: 1,
                    }}
                >
                    <SplideSlide>
                    <Card icon={Trophy} title={"COMPETITIVE MATCHES"} description={"Participate in competitive matches in Argentina, whether you're a professional or an amateur."}/>
                    </SplideSlide>
                    <SplideSlide>
                    <Card icon={Stadium} title={"LIVE PRO MATCHES"} description={"Experience the electrifying atmosphere of professional matches from the top clubs, and enjoy stadium tours."}/>
                    </SplideSlide>
                    <SplideSlide>
                    <Card icon={Player} title={"Pro-Coach Sessions"} description={"Train with top-tier coaches at high-caliber facilities, where the World Champion’s team trains."}/>
                    </SplideSlide>
                </Splide>
            </div>

        </div>
        </>
    )
}

export default ElevateYourTeam;